const InstallService = (function () {

    let installEvent = null;

    function saveInstallEvent(event) {
        if (event) {
            event.preventDefault();
            installEvent = event;
        }
    }

    function isInstallable() {
        if (installEvent) {
            return true;
        }
        return false;
    }

    //see: https://web.dev/customize-install/
    async function install() {
        if (installEvent) {
            installEvent.prompt();
            const {outcome} = await installEvent.userChoice;
            installEvent = null;
            return outcome;
        } else {
            return false;
        }
    }

    //see: https://web.dev/customize-install/
    function wasInstalled() {
        installEvent = null;
    }

    return {
        saveInstallEvent: saveInstallEvent,
        isInstallable: isInstallable,
        install: install,
        wasInstalled: wasInstalled
    }

})();


export default InstallService;