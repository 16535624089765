import _ from 'lodash';
import PersistentBusinessCardStore from "./PersistentBusinessCardStore";


/*BusinessCard Store. When persitentStorage is supported, it will save the Businesscards
* */
const BusinessCardStore = (function () {

    let businessCards = [];
    let storeIsInit = false;


    async function persistBusinessCards() {
        if (PersistentBusinessCardStore.supportsPersistentStorage() && storeIsInit) {
            await PersistentBusinessCardStore.saveAll(businessCards).catch(function (error) {
                console.log(error);
            })
        }
    }

    async function loadsBusinessCards() {
        if (PersistentBusinessCardStore.supportsPersistentStorage()) {
            return await PersistentBusinessCardStore.getAll().then(function (val) {
                businessCards = val ? val : [];
                storeIsInit = true;
                return businessCards;
            }).catch(function (error) {
                console.log(error);
            })
        } else {
            return businessCards;
        }
    }

    async function checkAndLoad() {
        if (!storeIsInit) {
            await loadsBusinessCards();
        }
    }

    async function save(card) {
        if (card && card.uuid) {
            let cardIndex = _.findIndex(businessCards, {"uuid": card.uuid});
            if (cardIndex !== -1) {
                businessCards[cardIndex] = card;
            } else {
                businessCards.push(card);
            }
            await persistBusinessCards();
        } else {
            throw "Businesscard has no UUID or is Null";
        }
    }

    async function saveAll(cards) {
        businessCards = cards;
        await persistBusinessCards();
    }

    async function getAll() {
        let businessCardsTmp = await loadsBusinessCards();
        return _.cloneDeep(businessCardsTmp);
    }

    async function getByID(id) {
        await checkAndLoad();
        let card = _.find(businessCards, {"uuid": id});
        return _.clone(card);
    }

    async function removeById(id) {
        await checkAndLoad();
        let cardIndex = _.findIndex(businessCards, {"uuid": id});
        if (cardIndex !== -1) {
            businessCards.splice(cardIndex, 1);
            await persistBusinessCards();
        }
    }

    async function removeByIndex(idx) {
        await checkAndLoad();
        if (idx < businessCards.length && idx >= 0) {
            businessCards.splice(idx, 1);
            await persistBusinessCards();
        }
    }

    async function remove(card) {
        await checkAndLoad();
        if (card && card.uuid) {
            await removeById(card.uuid);
        }
    }

    async function getByIndex(idx) {
        await checkAndLoad();
        return _.clone(businessCards[idx]);
    }


    return {
        save: save,
        saveAll: saveAll,
        remove: remove,
        removeById: removeById,
        getAll: getAll,
        getByID: getByID,
        getByIndex: getByIndex,
        removeByIndex: removeByIndex
    }
})();

export default BusinessCardStore;