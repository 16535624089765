import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {Link} from "react-router-dom";


function LinkIconButton(props) {


    return (
        <IconButton {...props} component={Link}>
            {props.children}
        </IconButton>
    );
}

LinkIconButton.defaultProps = {}

export default LinkIconButton;