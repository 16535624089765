import React from 'react';
import List from "@material-ui/core/List";
import BusinessCardListItem from "../BusinessCardListItem/BusinessCardListItem";
import {Link} from "react-router-dom";
import _ from 'lodash';


function BusinessCardList(props) {
    const {value, onDelete, onQRCode, ...args} = props;


    function handleDelete(uuid) {
        if (onDelete) {
            onDelete(uuid);
        }
    }

    function handleQRCode(uuid) {
        if (onQRCode) {
            onQRCode(uuid);
        }
    }


    return (
        <List {...args}>
            {_.sortBy(value, (bc) => {
                return bc.lastName + bc.firstName + bc.middleName;
            }).map((businessCard, index) =>
                <BusinessCardListItem value={businessCard} onDelete={handleDelete} onQRCode={handleQRCode}
                                      key={businessCard.uuid} component={Link}
                                      to={"/businesscard/" + businessCard.uuid}/>
            )}
        </List>
    );
}

BusinessCardList.defaultProps = {
    value: []
}

export default BusinessCardList;