import React from 'react';
import Avatar from "@material-ui/core/Avatar";
import UtilService from "../../services/UtilService";


function BusinessCardAvatar(props) {

    const {businesscard, ...args} = props;

    function getAcronym(businesscard) {
        let firstNameChar = UtilService.firstChar(businesscard.firstName).toUpperCase();
        let lastNameChar = UtilService.firstChar(businesscard.lastName).toUpperCase();
        return firstNameChar + lastNameChar;
    }

    return (
        <Avatar aria-label="avatar" {...args}>
            {getAcronym(businesscard)}
        </Avatar>
    );
}

BusinessCardAvatar.defaultProps = {
    className: "",
}

export default BusinessCardAvatar;