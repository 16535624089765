import React, {useEffect, useState} from 'react';
import MainDialog from "../../components/MainDialog/MainDialog";
import {Route, useHistory} from "react-router-dom";
import NewBusinessCardFAB from "../../components/NewBusinessCardFAB/NewBusinessCardFAB";
import BusinessCardList from "../../components/BusinessCardList/BusinessCardList";
import BusinessCardStore from "../../services/BusinessCardStore";


function WalletPage(props) {
    const [businessCardList, setBusinessCardList] = useState([]);
    const history = useHistory();


    useEffect(() => {
        BusinessCardStore.getAll().then(function (bcList) {
            setBusinessCardList(bcList);
        })
    }, [])

    function handleDelete(uuid) {
        BusinessCardStore.removeById(uuid).then(function () {
            BusinessCardStore.getAll().then(function (bcList) {
                setBusinessCardList(bcList);
            });
        });

    }

    function handleShowQRCode(uuid) {
        if (uuid) {
            history.push(`/businesscard/${uuid}/qrcode`);
        }
    }


    return (<React.Fragment>
        <BusinessCardList value={businessCardList} onQRCode={handleShowQRCode}
                          onDelete={handleDelete}></BusinessCardList>
        <Route path={"/businesscards/new"}>
            <MainDialog start={"camera"}></MainDialog>
        </Route>
        <Route path={"/businesscards/import"}>
            <MainDialog start={"import"}></MainDialog>
        </Route>
        <NewBusinessCardFAB/>
    </React.Fragment>);
}

WalletPage.defaultProps = {
    className: "",
}

export default WalletPage;