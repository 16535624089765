import React from 'react';
import Menu from "@material-ui/core/Menu";
import CameraService from "../../services/CameraService";
import NewBusinessCardMenuItem from "../NewBusinessCardMenuItem/NewBusinessCardMenuItem";
import {Link} from "react-router-dom";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ImageIcon from '@material-ui/icons/Image';
import SimpleFileService from "../../services/SimpleFileService";

function NewBusinessCardMenu(props) {
    const {onClose} = props;

    function handleClose(e) {
        if (onClose) {
            onClose();
        }
    }


    return (<React.Fragment>
        <Menu {...props} >
            <NewBusinessCardMenuItem
                itemText={"Kamera"}
                itemIcon={<AddAPhotoIcon></AddAPhotoIcon>}
                hidden={!CameraService.supportsCamera()}
                component={Link}
                to="/businesscards/new"
                onClick={(e) => {
                    handleClose(e);
                }}/>
            <NewBusinessCardMenuItem
                itemText={"Bild"}
                itemIcon={<ImageIcon></ImageIcon>}
                hidden={!SimpleFileService.isSupported()}
                component={Link}
                to="/businesscards/import"
                onClick={(e) => {
                    handleClose(e);
                }}/>
            <NewBusinessCardMenuItem
                itemText={"Manuell"}
                itemIcon={<ContactMailIcon></ContactMailIcon>}
                component={Link}
                to="/new"
                onClick={(e) => {
                    handleClose(e);
                }}/>
        </Menu>
    </React.Fragment>);
}

export default NewBusinessCardMenu;