import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import React from "react";

function FormInput(props) {
    const {id, label, helperText, className, ...args} = props;

    return (
        <FormControl className={className}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Input id={id} aria-describedby={id + "Helper"} {...args}/>
            {helperText && <FormHelperText id={id + "Helper"}>{helperText}</FormHelperText>}
        </FormControl>
    );
}

FormInput.defaultProps = {
    id: "",
    label: "",
    helperText: null,
    className: null,
}


export default FormInput;