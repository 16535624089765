import QrScanner from "qr-scanner";

const QRScannerLibWrapper = (function () {
    const HARDCODED_HOST = "";
    const WORKER_PATH = 'libs/qrscanner/qr-scanner-worker.min.js';

    let engine;

    function getCurrentPath() {
        let executionPath = "";
        if (window && window.location && window.location.origin) {
            executionPath = window.location.origin + "/";
        } else if (window && window.location && window.location.href) {
            let url = window.location.href
            let arr = url ? url.split("/") : null;
            let result = arr && arr.length > 2 ? arr[0] + "//" + arr[2] : "";
            executionPath = result + "/"
        } else {
            executionPath = HARDCODED_HOST;
        }
        return executionPath;
    }


    async function initializeEngine() {
        if (!engine) {
            let currentPath = getCurrentPath();
            QrScanner.WORKER_PATH = currentPath + WORKER_PATH;
            let currentEngine = await QrScanner.createQrEngine(QrScanner.WORKER_PATH);
            engine = currentEngine;
            return currentEngine;
        }
        return engine;
    }

    function cleanup() {
        if (engine) {
            QrScanner._postWorkerMessage(engine, 'close');
            engine = null;
        }

    }


    async function scanStream(videoElement, resolveParam) {
        let resolveFunction;
        let promise;
        if (!resolveParam) {
            promise = new Promise((resolve) => {
                resolveFunction = resolve;
            });
            await initializeEngine();
        } else {
            resolveFunction = resolveParam;
        }

        let qrCodeEngine = engine;

        if (videoElement && qrCodeEngine) {
            window.requestAnimationFrame(() => {
                /*Api states, that it supports Video-Elements. Current Frame is used.
                 * see: https://wicg.github.io/shape-detection-api/#image-sources-for-detection
                 */
                if (videoElement.srcObject && videoElement.srcObject.active && !videoElement.ended) {
                    if (videoElement.readyState && videoElement.readyState > 1) {
                        QrScanner.scanImage(videoElement, null, qrCodeEngine, null).then(function (result) {
                            resolveFunction(result);
                        }).catch(function () {
                            scanStream(videoElement, resolveFunction);
                        })
                    } else {
                        scanStream(videoElement, resolveFunction);
                    }

                }

            });
        }

        if (promise) {
            return promise;
        }


    }

    /* async function scanStream(videoElem){
         initialize();
         const promise = new Promise((resolve,reject) =>{
             const qrScanner = new QrScanner(videoElem, (result) => {
                 resolve(result);
             });
            qrScanner.start();
         })
         return promise;
     }*/

    async function detectQRCode(image) {
        let currentEngine = await initializeEngine();
        return QrScanner.scanImage(image, null, currentEngine, null)
    }

    async function hasQRCode(image) {
        const qrcodes = await detectQRCode();
        if (qrcodes && qrcodes.length > 0) {
            return true;
        }
        return false;
    }

    return {
        scanStream: (videElem) => {
            return scanStream(videElem);
        },
        detectQRCode: detectQRCode,
        hasQRCode: hasQRCode,
        cleanup: cleanup
    }

})();


export default QRScannerLibWrapper;