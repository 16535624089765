import React, {useEffect, useState} from 'react';
import SimpleDialog from "../SimpleDialog/SimpleDialog";
import BusinessCardQRCode from "../BusinessCardQRCode/BusinessCardQRCode";


function BusinessCardQRCodeDialog(props) {
    const {className, onClose, value} = props;
    const [businessCard, setBusinessCard] = useState(null);

    useEffect(() => {
        if (value) {
            setBusinessCard(value);
        }
    }, [value])


    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    return (
        <SimpleDialog className={className} titleText={"QRCode"} onClose={handleClose}>
            <BusinessCardQRCode businessCard={businessCard}/>
        </SimpleDialog>
    );
}

BusinessCardQRCodeDialog.defaultProps = {
    className: "",
}


export default BusinessCardQRCodeDialog;