import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    circularProgess: {
        zIndex: 6,
    },
    overlay: {
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
        justifyContent: "center",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 5,
    }
}));


function ProgressSpinner(props) {
    const css = useStyles();

    return (
        <Box className={css.overlay}>
            <CircularProgress className={css.circularProgess} color={"secondary"} {...props}/>
        </Box>
    );
}

export default ProgressSpinner;