import React, {useEffect, useState} from 'react';
import CameraDialogStep from "../CameraDialogStep/CameraDialogStep";
import RecognationDialogStep from "../RecognationDialogStep/RecognationDialogStep";
import {useHistory} from "react-router-dom";
import BusinessCardMappingService from "../../services/BusinessCardMappingService";
import SimpleDialog from "../SimpleDialog/SimpleDialog";
import UploadDialogStep from "../UploadDialogStep/UploadDialogStep";


function MainDialog(props) {
    const {className, start} = props;
    const [dialogTitle, setDialogTitle] = useState("");
    const [photoUrl, setPhotoUrl] = useState(null);
    const history = useHistory();

    const START_TYPE = {
        CAMERA: "camera",
        IMPORT: "import"
    };


    useEffect(() => {
        return cleanUp;
    }, [])


    function cleanUp() {
        setPhotoUrl(null);
    }

    function handleClose() {
        cleanUp();
        history.push("/")
    }

    function handlePhoto(photoUrl) {
        if (photoUrl) {
            setPhotoUrl(photoUrl);
        }
    }

    function handleBCResult(dto) {
        if (dto) {
            toBusinessCardPage(dto);
        }
    }

    function handleQRCode(qrCode) {
        if (qrCode) {
            let dto = BusinessCardMappingService.vCardStringToDTO(qrCode);
            toBusinessCardPage(dto);
        }
    }

    function toBusinessCardPage(dto) {
        history.push("/new", dto);
    }

    function handleTitleChangeRequest(titleText) {
        if (titleText) {
            setDialogTitle(titleText);
        }
    }

    return (
        <SimpleDialog className={className} titleText={dialogTitle} onClose={handleClose}>

            {(!photoUrl && start === START_TYPE.CAMERA) &&
            <CameraDialogStep onTitleChangeRequest={handleTitleChangeRequest} onQRCode={handleQRCode}
                              onPhoto={handlePhoto}/>}
            {(!photoUrl && start === START_TYPE.IMPORT) &&
            <UploadDialogStep onTitleChangeRequest={handleTitleChangeRequest} onImportImage={handlePhoto}/>}
            {photoUrl &&
                <RecognationDialogStep onTitleChangeRequest={handleTitleChangeRequest} photo={photoUrl}
                                       onBCResult={handleBCResult}></RecognationDialogStep>
            }


        </SimpleDialog>
    );
}

MainDialog.defaultProps = {
    className: "",
    start: "camera"
}


export default MainDialog;