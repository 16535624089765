import NativeQRCodeScanner from "./NativeQRCodeScanner";
import QRScannerLibWrapper from "./QRScannerLibWrapper";
import _ from 'lodash';

const QRCodeScanner = (function () {


    async function scanStream(videoElement) {
        let supportsFeature = await NativeQRCodeScanner.supportsNativeQRScanner();
        if (supportsFeature) {
            return NativeQRCodeScanner.scanStream(videoElement).then(function (result) {
                //Non Native QR-Code-Scanner ony
                if (result && result.length) {
                    return _.map(result, (barCode) => {
                        return (barCode && barCode.rawValue) ? barCode.rawValue : "";
                    });
                } else {
                    return [];
                }
            });
        }
        return QRScannerLibWrapper.scanStream(videoElement).then(function (result) {
            return [result];
        });

    }


    async function detectQRCode(image) {
        let supportsFeature = await NativeQRCodeScanner.supportsNativeQRScanner();
        if (supportsFeature) {
            return NativeQRCodeScanner.detectQRCode(image).then(function (result) {
                //Non Native QR-Code-Scanner ony
                if (result && result.length) {
                    return _.map(result, (barCode) => {
                        return (barCode && barCode.rawValue) ? barCode.rawValue : "";
                    });
                } else {
                    return [];
                }
            });
        }
        return QRScannerLibWrapper.detectQRCode(image).then(function (result) {
            return [result];
        });
    }


    async function hasQRCode(image) {
        let supportsFeature = await NativeQRCodeScanner.supportsNativeQRScanner();
        if (supportsFeature) {
            return NativeQRCodeScanner.hasQRCode(image);
        }
        return QRScannerLibWrapper.hasQRCode(image);
    }

    function cleanup() {
        //i think it has a better performance and is less complex,
        //then checkin which Scanner was used;
        QRScannerLibWrapper.cleanup();
        NativeQRCodeScanner.cleanup();
    }


    return {
        detectQRCode: detectQRCode,
        scanStream: scanStream,
        hasQRCode: hasQRCode,
        cleanup: cleanup
    }

})();


export default QRCodeScanner;