import React, {useState} from 'react';
import Fab from "@material-ui/core/Fab";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import {makeStyles} from "@material-ui/core/styles";
import NewBusinessCardMenu from "../NewBusinessCardMenu/NewBusinessCardMenu";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    contactPhoneIcon: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    cameraFab: {
        margin: '0 auto',

    }
}));


function NewBusinessCardFAB(props) {
    const css = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuIsOpen, setMenuOpen] = useState(false);


    function toogleMenu(e) {
        if (e) {
            setAnchorEl(e.currentTarget);
        }
        setMenuOpen(!menuIsOpen);
    }


    return (<React.Fragment>
        <NewBusinessCardMenu onClose={toogleMenu} anchorEl={anchorEl} open={menuIsOpen}
                             anchorOrigin={{
                                 vertical: 'top',
                                 horizontal: 'center',
                             }}
                             transformOrigin={{
                                 vertical: 'bottom',
                                 horizontal: 'right',
                             }}/>
        <Fab id="actionButton" color="primary" aria-label="add" className={css.fab} onClick={toogleMenu}>
            <PersonAddIcon></PersonAddIcon>
        </Fab>
    </React.Fragment>);
}

NewBusinessCardFAB.defaultProps = {
    className: "",
}

export default NewBusinessCardFAB;