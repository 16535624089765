import React, {useEffect, useRef, useState} from 'react';
import Box from '@material-ui/core/Box';
import CameraService from "../../services/CameraService";
import QRCodeScanner from "../../services/QRCodeScanner";


function Camera(props) {
    const {
        className,
        onPhoto,
        photoEventRegister,
        onCameraSwitch,
        switchCameraEventRegister,
        onQRCode
    } = props;

    const videoStreamRef = useRef();
    const [stream, setStream] = useState(null);


    useEffect(() => {
        //start Streaming when Hook is mounted
        if (photoEventRegister) {
            photoEventRegister(makePhoto);
        }
        if (switchCameraEventRegister) {
            switchCameraEventRegister(switchCamera);
        }
        startStreaming();

        //if returning a function react will calling it for cleanup
        //see: https://reactjs.org/docs/hooks-effect.html
        return function cleanup() {
            QRCodeScanner.cleanup();
            stopStreaming();
        }
    }, [])

    useEffect(() => {
        videoStreamRef.current.srcObject = stream;
        if (videoStreamRef.current && videoStreamRef.current.srcObject && onQRCode) {
            let videoElement = videoStreamRef.current;
            QRCodeScanner.scanStream(videoElement).then((qrCodes) => {
                if (qrCodes && qrCodes.length > 0) {
                    onQRCode(qrCodes[0]);
                }
            });
        }
    }, [stream])


    function switchCamera() {
        if (CameraService.supportsDeviceEnumeration()) {
            CameraService.switchCamera().then(function (videoStream) {
                setStream(videoStream);
                if(onCameraSwitch){
                    onCameraSwitch();
                }
            }).catch(function (error) {
                alert(error);
            });
        }

    }

    function makePhoto() {
        if (CameraService.isStreamActive()) {
            CameraService.takePhoto().then(function (imageURL) {
                onPhoto(imageURL);
            });
        }
    }


    function startStreaming() {
        CameraService.getDefaultVideoStream().then((stream) => {
            setStream(stream);
            //videoStreamRef.current.srcObject = stream;
        }).catch((e) => {
            alert(e);
        });
    }

    function stopStreaming() {
        CameraService.stopStream();
    }

    return (
        <Box className={className}>
            <video autoPlay style={{width: "100%", heigth: "100%"}} ref={videoStreamRef}></video>
        </Box>
    );
}

Camera.defaultProps = {
    className: "",
}

export default Camera;