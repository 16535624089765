import FileSystemAccessAPIService from "./FileSystemAccessAPIService";
import FileAPIService from "./FileAPIService";

const SimpleFileService = (function () {


    function getImportFileTypes() {
        if (FileSystemAccessAPIService.isSupported()) {
            return FileSystemAccessAPIService.getImportFileTypes();
        } else {
            return FileAPIService.getImportFileTypes();
        }
    }

    function getExportFileTypes() {
        if (FileSystemAccessAPIService.isSupported()) {
            return FileSystemAccessAPIService.getExportFileTypes();
        } else {
            return FileAPIService.getExportFileTypes();
        }
    }


    function isSupported() {
        return FileAPIService.isSupported() || FileSystemAccessAPIService.isSupported();
    }

    async function exportFile(dataURL, type, fileName) {
        if (FileSystemAccessAPIService.isSupported()) {
            return FileSystemAccessAPIService.exportFile(dataURL, type);
        }
        return FileAPIService.exportFile(dataURL, type, fileName);
    }

    async function importFile(type) {
        if (FileSystemAccessAPIService.isSupported()) {
            return FileSystemAccessAPIService.importFile([type]);
        }
        return FileAPIService.importFile(type);
    }

    return {
        importFile: importFile,
        isSupported: isSupported,
        getImportFileTypes: getImportFileTypes,
        getExportFileTypes: getExportFileTypes,
        exportFile: exportFile
    }

})();


export default SimpleFileService;