import BusinessCardDTO from "../model/BusinessCardDTO";
import {v4 as uuid} from 'uuid';
import _ from 'lodash';
import vCardsJS from "vcards-js";
import BCRLibWrapperService from "./BCRLibWrapperService";

class BusinessCardMappingService {


    static epmtyBusinessCardDTO() {
        let newDTO = _.cloneDeep(BusinessCardDTO);
        newDTO.uuid = uuid();
        return newDTO;
    }

    static vCardStringToDTO(vCard) {
        let bcrObj = BCRLibWrapperService.analyzeVCardString(vCard);
        let newDTO = this.newDTO();
        if (bcrObj && bcrObj.fields) {
            this.bcrLibFieldsToBusinessCardDTO(newDTO, bcrObj.fields);
        }
        return newDTO;
    }

    static newDTO() {
        let newDTO = _.cloneDeep(BusinessCardDTO);
        newDTO.uuid = uuid();
        return newDTO;
    }

    static bcrLibFieldsToBusinessCardDTO(newDTO, fields) {
        newDTO.company = fields.Company ? fields.Company : "";
        newDTO.job = fields.Job ? fields.Job : "";

        if (fields.Name) {
            const name = fields.Name;

            newDTO.lastName = name.Surname ? name.Surname : "";

            if (name.Name) {
                newDTO.firstName = name.Name.FirstName ? name.Name.FirstName : "";
                newDTO.middleName = name.Name.MiddleName ? name.Name.MiddleName : "";
                newDTO.extraName = name.Name.ExtraName ? name.Name.ExtraName : "";

            }
        }

        if (fields.Address) {
            const adress = fields.Address;

            newDTO.street = adress.StreetAddress ? adress.StreetAddress : "";
            newDTO.plz = adress.ZipCode ? adress.ZipCode : "";
            newDTO.country = adress.Country ? adress.Country : "";
            newDTO.city = adress.City ? adress.City : "";
            newDTO.label = adress.Text ? adress.Text : "";

        }

        newDTO.phone = fields.Phone ? fields.Phone : "";
        newDTO.mobile = fields.Mobile ? fields.Mobile : "";
        newDTO.email = fields.Email ? fields.Email : "";
        newDTO.web = fields.Web ? fields.Web : "";
        newDTO.fax = fields.Company ? fields.Company : "";
        newDTO.notes = fields.Text ? fields.Text : "";

    }

    static bcrLibResultToBusinessCardDTO(bcrObj, image) {
        let newDTO = this.newDTO();

        if (bcrObj && bcrObj.result) {
            const fields = bcrObj.result;
            this.bcrLibFieldsToBusinessCardDTO(newDTO, fields);
        }

        if (image) {
            newDTO.image = image;
        }

        if (bcrObj && bcrObj.blocks) {
            let notes = "";
            _.forEach(function (block) {
                if (block && block.text) {
                    notes = notes + block.text;
                }
            })
            newDTO.notes = notes;
        }

        return newDTO;

    }


    static businessCardDTOToVCard(businessCardDTO, version = "3.0") {
        let newVCardJS = vCardsJS();
        newVCardJS.version = version ? version : '3.0';

        if (businessCardDTO) {
            newVCardJS.uid = businessCardDTO.uuid ? businessCardDTO.uuid : uuid();
            newVCardJS.firstName = businessCardDTO.firstName ? businessCardDTO.firstName : null;
            newVCardJS.middleName = businessCardDTO.middleName ? businessCardDTO.middleName : null;
            newVCardJS.lastName = businessCardDTO.lastName ? businessCardDTO.lastName : null;
            newVCardJS.nickname = businessCardDTO.extraName ? businessCardDTO.extraName : null;
            newVCardJS.role = businessCardDTO.job ? businessCardDTO.job : null;
            newVCardJS.organization = businessCardDTO.company ? businessCardDTO.company : null;
            newVCardJS.workAddress.street = businessCardDTO.street ? businessCardDTO.street : null;
            newVCardJS.workAddress.postalCode = businessCardDTO.plz ? businessCardDTO.plz : null;
            newVCardJS.workAddress.city = businessCardDTO.city ? businessCardDTO.city : null;
            newVCardJS.workAddress.countryRegion = businessCardDTO.country ? businessCardDTO.country : null;
            newVCardJS.homeAddress.label = businessCardDTO.label ? businessCardDTO.label : null;
            newVCardJS.workPhone = businessCardDTO.phone ? businessCardDTO.phone : null;
            newVCardJS.cellPhone = businessCardDTO.mobile ? businessCardDTO.mobile : null;
            newVCardJS.workEmail = businessCardDTO.email ? businessCardDTO.email : null;
            newVCardJS.workUrl = businessCardDTO.web ? businessCardDTO.web : null;
            newVCardJS.workFax = businessCardDTO.fax ? businessCardDTO.fax : null;
            newVCardJS.note = businessCardDTO.notes ? businessCardDTO.notes : null;

        }

        return newVCardJS;


    }

    static businessCardDTOToVCardString(businessCardDTO) {
        let vCardObj = this.businessCardDTOToVCard(businessCardDTO);
        return vCardObj.getFormattedString();
    }

    static businessCardDTOToVCardDataURL(businessCardDTO) {
        const VCARD_DATAURL_PREFIX = "data:text/x-vcard;base64,";
        let vCardString = this.businessCardDTOToVCardString(businessCardDTO);
        let base64 = btoa(unescape(encodeURIComponent(vCardString)));
        let vCardDataURL = VCARD_DATAURL_PREFIX + base64;
        return vCardDataURL;
    }
}

export default BusinessCardMappingService;