import UtilService from "./UtilService";

const FileSystemAccessAPIService = (function () {

    const IMPORT_FILE_TYPES = {
        IMAGE: {
            description: 'Images',
            accept: {
                'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
            }
        }
    }

    const EXPORT_FILE_TYPES = {
        VCARD: {
            description: 'vcard',
            accept: {
                'text/x-vcard': ['.vcf'],
            }
        }
    }

    function getImportFileTypes() {
        return IMPORT_FILE_TYPES;
    }

    function getExportFileTypes() {
        return EXPORT_FILE_TYPES;
    }


    function isSupported() {
        return ('showOpenFilePicker' in window && 'File' in window && 'showSaveFilePicker' in window);
    }


    //see:https://developer.mozilla.org/en-US/docs/Web/API/File_System_Access_API
    async function importFile(types) {
        let fileTypes = types ? types : [IMPORT_FILE_TYPES.IMAGE];
        const [handle] = await window.showOpenFilePicker({
            multiple: false,
            excludeAcceptAllOption: true,
            types: fileTypes
        });
        if (handle) {
            const file = await handle.getFile();
            const fileURL = await UtilService.toDataURL(file);
            return fileURL;
        }
    }

    //see:https://developer.mozilla.org/en-US/docs/Web/API/File_System_Access_API
    //see:https://web.dev/file-system-access/
    //see:https://stackoverflow.com/questions/11876175/how-to-get-a-file-or-blob-from-an-object-url
    async function exportFile(dataURL, type) {
        const fileTypes = type ? [type] : [EXPORT_FILE_TYPES.VCARD];
        const file = await UtilService.dataURLtoFile(dataURL);
        const handle = await window.showSaveFilePicker({
            types: fileTypes
        });
        const writableStream = await handle.createWritable();
        await writableStream.write(file);
        await writableStream.close();
    }


    return {
        importFile: importFile,
        isSupported: isSupported,
        exportFile: exportFile,
        getImportFileTypes: getImportFileTypes,
        getExportFileTypes: getExportFileTypes
    }

})();


export default FileSystemAccessAPIService;