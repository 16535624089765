const BusinessCardDTO = {
    "image" : "",
    "uuid" : "",
    //Names
    "firstName" : "",
    "middleName" : "",
    "lastName" : "",
    "extraName" : "",
    //Job
    "job" :"",
    "company" : "",
    //Adresse
    "street" : "",
    "plz" : "",
    "city" : "",
    "country" : "",
    "label" : "",
    //Contact-Data
    "phone" : "",
    "mobile" : "",
    "email" : "",
    "web" : "",
    "fax" : "",
    //notes
    "notes":"",

}
export default BusinessCardDTO;
