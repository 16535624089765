import React, {useEffect, useRef, useState} from 'react';
import DialogActions from "@material-ui/core/DialogActions";
import Fab from "@material-ui/core/Fab";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from '@material-ui/core/DialogContent';
import BCRLibWrapperService, {languages} from "../../services/BCRLibWrapperService";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ProgressSpinner from "../ProgressSpinner/ProgressSpinner";
import BusinessCardMappingService from "../../services/BusinessCardMappingService";

const useStyles = makeStyles((theme) => ({
    cameraFab: {
        marginLeft: "auto",
        marginRight: "auto",

    },
    cameraContent: {
        maxWidth: "100%",
        maxHeight: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
        width: "auto",
        height: "auto",
        flexShrink: 0,
    },
    //TODO: von oben als default durchreichen
    dialogContent: {
        padding: 0,
        margin: 0,
        position: "relative",
        display: "flex",
        maxHeight: "100%",
        backgroundColor: "black",
    }
}));


function RecognationDialogStep(props) {
    const {onTitleChangeRequest, photo, onBCResult} = props;

    const [isWorking, setIsWorking] = useState(false);
    const [engineIsReady, setEngineIsReady] = useState(false);

    const css = useStyles();
    const imageRef = useRef();

    useEffect(() => {
        handleTitleChange("Recognize Contactdata");
        init(photo);
        return cleanup;
    }, [])


    function init(photo) {
        imageRef.current.src = photo;
        BCRLibWrapperService.init(languages.GERMAN).then(function () {
            setEngineIsReady(true);
        }).catch(function () {
            setEngineIsReady(false);
        });
    }

    function cleanup() {
        setIsWorking(false);
        setEngineIsReady(false);
        BCRLibWrapperService.cleanup();
    }

    function convertToDto(bcr, image) {
        const dto = BusinessCardMappingService.bcrLibResultToBusinessCardDTO(bcr, image);
        return dto;
    }

    function handleRecognizeBCR() {
        setIsWorking(true);
        BCRLibWrapperService.recognize(photo).then(function (bcr) {
            setIsWorking(false);
            if (onBCResult) {
                let dto = convertToDto(bcr, photo);
                onBCResult(dto);
            }

        }).catch(function (error) {
            setIsWorking(false);
        })
    }

    function engineUnresponsive() {
        return isWorking || !engineIsReady;
    }

    function handleTitleChange(title) {
        if (onTitleChangeRequest) {
            onTitleChangeRequest(title);
        }
    }


    return (
        <React.Fragment>
            <DialogContent className={css.dialogContent}>
                {engineUnresponsive() && <ProgressSpinner variant="indeterminate"></ProgressSpinner>}
                <img alt={"Businesscard!"} className={css.cameraContent} ref={imageRef}></img>
            </DialogContent>
            <DialogActions>
                <Fab size={"small"} className={css.cameraFab} color="primary" aria-label="dummyicon" disabled>
                    <React.Fragment></React.Fragment>
                </Fab>
                <Fab size={"large"} className={css.cameraFab} color="primary" aria-label="Analyze BusinessCard!"
                     disabled={engineUnresponsive()} onClick={handleRecognizeBCR}>
                    <FindInPageIcon></FindInPageIcon>
                </Fab>
                <Fab size={"small"} className={css.cameraFab} color="primary" aria-label="dummyicon" disabled>
                    <React.Fragment></React.Fragment>
                </Fab>
            </DialogActions>
        </React.Fragment>
    );
}

RecognationDialogStep.defaultProps = {
    className: "",
    dialogTitle: "",
    photo: "",
    onBCResult: () => {
    }
}


export default RecognationDialogStep;