import React, {useEffect, useState} from 'react';
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Route, Switch} from "react-router-dom";
import WalletPage from "./pages/WalletPage/WalletPage";
import BusinessCardPage from "./pages/BusinessCardPage/BusinessCardPage";
import Container from '@material-ui/core/Container';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InstallService from "./services/InstallService";
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    contactPhoneIcon: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    cameraFab: {
        margin: '0 auto',

    },
    dialogcontent: {
        backgroundColor: "black"
    }
}));

function App() {

    const css = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isInstallable, setIsInstallable] = useState(false);

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (event) => {
            InstallService.saveInstallEvent(event);
            setIsInstallable(InstallService.isInstallable());
        });

        window.addEventListener('appinstalled', () => {
            InstallService.wasInstalled();
            setIsInstallable(InstallService.isInstallable());
        });

    }, [])

    function handleInstall() {
        InstallService.install();
    }


    return (
        <div className={css.root}>
            <AppBar position="static">
                <Toolbar>
                    <ContactPhoneIcon edge="start" className={css.contactPhoneIcon} fontSize="large"
                                      aria-label="menu"></ContactPhoneIcon>
                    <Typography variant="h6" className={css.title}>Business Card Wallet</Typography>
                    {isInstallable && <Button aria-label="delete" color="inherit" onClick={handleInstall} startIcon={<GetAppIcon></GetAppIcon>}>Install</Button>}
                </Toolbar>
            </AppBar>
            <Container disableGutters={fullScreen} maxWidth="sm">
                <Switch>
                    <Route path={["/", "/businesscards", "/businesscards/new", "/businesscards/import"]} exact component={WalletPage}>
                    </Route>
                    <Route path={["/businesscard", "/new", "/businesscard/:id", "/businesscard/:id/qrcode"]} exact
                           component={BusinessCardPage}>
                    </Route>
                </Switch>
            </Container>
        </div>
    );
}

export default App;
