import React, {useEffect} from 'react';
import DialogActions from "@material-ui/core/DialogActions";
import Fab from "@material-ui/core/Fab";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from '@material-ui/core/DialogContent';
import PublishIcon from '@material-ui/icons/Publish';
import SimpleFileService from "../../services/SimpleFileService";


const useStyles = makeStyles((theme) => ({
    fab: {
        marginLeft: "auto",
        marginRight: "auto",

    },
    cameraContent: {
        maxWidth: "100%",
        maxHeight: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
        width: "auto",
        height: "auto",
        flexShrink: 0,
    },
    dialogContent: {
        padding: 0,
        margin: 0,
        minHeight: "20vh",
        display: "flex",
        maxHeight: "100%",
        backgroundColor: "black",
    }
}));


function UploadDialogStep(props) {
    const {className, onTitleChangeRequest, onImportImage} = props;
    const css = useStyles();

    useEffect(() => {
        handleTitleChange("Upload Image!");
    }, [])


    function handleTitleChange(title) {
        if (onTitleChangeRequest) {
            onTitleChangeRequest(title);
        }
    }


    function handleLoadImage(e) {
        if (onImportImage) {
            SimpleFileService.importFile(SimpleFileService.getImportFileTypes().IMAGE).then((file) => {
                onImportImage(file);
            })
        }
    }


    return (
        <React.Fragment>
            <DialogContent className={css.dialogContent}>
            </DialogContent>
            <DialogActions>
                <Fab size={"small"} className={css.fab} color="primary" aria-label="dummyicon" disabled>
                    <React.Fragment></React.Fragment>
                </Fab>
                <Fab size={"large"} className={css.fab} color="primary" aria-label="Upload File!" autoFocus
                     onClick={handleLoadImage}>
                    <PublishIcon></PublishIcon>
                </Fab>
                <Fab size={"small"} className={css.fab} color="primary" aria-label="dummyicon" disabled>
                    <React.Fragment></React.Fragment>
                </Fab>
            </DialogActions>
        </React.Fragment>
    );
}

UploadDialogStep.defaultProps = {
    className: "",
    dialogTitle: "",
    onImportImage: () => {
    },
}


export default UploadDialogStep