import {
    bcr,
    cropStrategy,
    languages,
    ocrEngines,
    tesseractVersions,
    VCardParse
} from "../libs/bcrminifed/bcr.tesseractv2.min.js";

/* changes lib, so babel has no Problem*/
/* eslint no-undef: "off"*/
/*
* Wrapper für die BCRLibrary. Ermöglicht es zukünftig wenn notwendig die Impl. auszutauschen oder zusätzliche Funktionen
* zu implementieren.
* */
const BCRLibWrapperService = (function () {


    const DEFAULT_ENGINE = ocrEngines.TESSERACT;
    const DEFAULT_CROP_STRATEGY = cropStrategy.SMART;
    const DEFAULT_LANGUAGE = languages.GERMAN;
    const DEFAULT_VERSION = tesseractVersions.V2;


    async function init(language = DEFAULT_LANGUAGE) {
        return await bcr.initialize(DEFAULT_ENGINE, DEFAULT_CROP_STRATEGY, language, undefined, undefined, false, false, DEFAULT_VERSION);
    }

    function analyzeVCardString(vCard) {
        return VCardParse(vCard);
    }

    async function cleanup() {
        return await bcr.cleanUp();
    }

    //mapping von Callback auf Promise
    async function recognize(base64image, displayProgressCallback) {
        let progress;

        if (displayProgressCallback) {
            progress = displayProgressCallback;
        } else {
            progress = function (message) {
                console.log(message);
            }
        }


        return new Promise((resolve) => {
            bcr.recognize(base64image, (data) => {
                resolve(data);
            }, progress);
        })

    }


    return {
        init: init,
        recognize: recognize,
        cleanup: cleanup,
        analyzeVCardString: analyzeVCardString,
    }
})();

export {BCRLibWrapperService, languages}
export default BCRLibWrapperService;