import React, {useEffect, useState} from 'react';
import BusinessCard from "../../components/BusinessCard/BusinessCard";
import BusinessCardStore from "../../services/BusinessCardStore";
import BusinessCardMappingService from "../../services/BusinessCardMappingService";
import _ from 'lodash';
import BusinessCardQRCodeDialog from "../../components/BusinessCardQRCodeDialog/BusinessCardQRCodeDialog";
import {Route, useHistory, useParams, useRouteMatch} from "react-router-dom";


function BusinessCardPage(props) {

    const {...args} = props;
    const [isReadonly, setIsReadonly] = useState(true);
    const [businessCardDTO, setBusinessCardDTO] = useState(BusinessCardMappingService.epmtyBusinessCardDTO());
    const history = useHistory();
    const match = useRouteMatch();
    const {id} = useParams();

    const PARAMS_PATH_LOCATION = ["location", "state"];


    useEffect(() => {
        if (id) {
            //Open From ItemList
            setIsReadonly(true);
            BusinessCardStore.getByID(id).then(function (businessCard) {
                setBusinessCardDTO(businessCard);
            });

        } else if (_.has(props, PARAMS_PATH_LOCATION) && _.get(props, PARAMS_PATH_LOCATION)) {
            //From Main-Dialog
            let bc = _.get(props, PARAMS_PATH_LOCATION);
            setIsReadonly(false);
            handleChange(bc);
        } else {
            //From Menu for manual BusinessCard Creation
            setIsReadonly(false);
        }

        return function cleanup() {
            setBusinessCardDTO(null);
        }

    }, [])

    function handleChange(bc) {
        setBusinessCardDTO(bc);
        BusinessCardStore.save(bc);
    }

    function handleShowQRCode(uuid) {
        if (uuid) {
            history.push(`/businesscard/${uuid}/qrcode`);
        }
    }

    function handleCloseQRDialog() {
        history.goBack();
    }


    return (
        <React.Fragment>
            <Route path={[`/businesscard/:id`, `/businesscard`, `/new`]}>
                <BusinessCard onShowQRCode={handleShowQRCode} value={businessCardDTO} readOnly={isReadonly}
                              onChange={handleChange}/>
            </Route>
            <Route path={`/businesscard/:id/qrcode`}>
                <BusinessCardQRCodeDialog onClose={handleCloseQRDialog} value={businessCardDTO}/>
            </Route>
        </React.Fragment>
    );
}

BusinessCardPage.defaultProps = {
    className: "",
}

export default BusinessCardPage;