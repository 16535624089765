import QRCode from 'qrcode';
import BusinessCardMappingService from "./BusinessCardMappingService";

const BusinessCardQRCodeGenerator = (function () {

    async function dtoToDataUrl(dto) {
        let vCardString = BusinessCardMappingService.businessCardDTOToVCardString(dto);
        return QRCode.toDataURL(vCardString);
    }

    async function dtoToCanvas(canvas, dto) {
        let vCardString = BusinessCardMappingService.businessCardDTOToVCardString(dto);
        return QRCode.toCanvas(canvas, vCardString);
    }

    return {
        dtoToCanvas: dtoToCanvas,
        dtoToDataUrl: dtoToDataUrl
    }

})();


export default BusinessCardQRCodeGenerator;