import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import BusinessCardAvatar from "../BusinessCardAvatar/BusinessCardAvatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from '@material-ui/icons/Delete';
import BusinessCardMappingService from "../../services/BusinessCardMappingService";
import DashboardIcon from "@material-ui/icons/Dashboard";


function BusinessCardListItem(props) {
    const {value, onDelete, onQRCode, ...args} = props;



    function handleDelete(event) {
        event.preventDefault();
        if (onDelete) {
            onDelete(value.uuid);
        }
    }

    function handleQRCode(event) {
        event.preventDefault();
        if (onQRCode) {
            onQRCode(value.uuid);
        }
    }


    return (
        <ListItem button {...args}>
            <ListItemAvatar>
                <BusinessCardAvatar businesscard={value}/>
            </ListItemAvatar>
            <ListItemText primary={value.firstName + " " + value.lastName}
                          secondar={value.uuid}>
            </ListItemText>
            <IconButton aria-label="delete Businesscard" onClick={handleDelete}>
                <DeleteIcon/>
            </IconButton>
            <IconButton aria-label="show QR Code" onClick={handleQRCode}>
                <DashboardIcon></DashboardIcon>
            </IconButton>
        </ListItem>
    );
}

BusinessCardListItem.defaultProps = {
    value: BusinessCardMappingService.epmtyBusinessCardDTO()
}

export default BusinessCardListItem;