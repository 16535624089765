import React, {useEffect, useState} from 'react';
import DialogActions from "@material-ui/core/DialogActions";
import Fab from "@material-ui/core/Fab";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Camera from "../Camera/Camera";
import {makeStyles} from "@material-ui/core/styles";
import DialogContent from '@material-ui/core/DialogContent';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import CameraService from "../../services/CameraService";

const useStyles = makeStyles((theme) => ({
    cameraFab: {
        marginLeft: "auto",
        marginRight: "auto",

    },
    cameraContent: {
        maxWidth: "100%",
        maxHeight: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
        width: "auto",
        height: "auto",
        flexShrink: 0,
    },
    dialogContent: {
        padding: 0,
        margin: 0,
        display: "flex",
        maxHeight: "100%",
        backgroundColor: "black",
    }
}));


function CameraDialogStep(props) {
    const {onTitleChangeRequest, onPhoto, onQRCode} = props;
    const css = useStyles();
    const [takePhotoCallback, setTakePhotoCallback] = useState(() => () => {
    });
    const [switchCameraCallback, setSwitchCameraCallback] = useState(() => () => {
    });


    //set intern state when open is changend in parent component
    useEffect(() => {
        handleTitleChange("Make a Photo!");
    }, [])


    function handlePhotoIsTaken(photo) {
        if (onPhoto && photo) {
            onPhoto(photo);
        }
    }

    function handleSwitchIsTaken() {
        console.log("Camera was switched!");
    }

    function registerTakePhoto(callBackFunc) {
        setTakePhotoCallback(() => () => callBackFunc());
    }

    function registerSwitchPhoto(callBackFunc) {
        setSwitchCameraCallback(() => () => callBackFunc());
    }

    function handleMakePhoto() {
        if (takePhotoCallback) {
            takePhotoCallback();
        }
    }

    function handleSwitchCamera() {
        if (switchCameraCallback) {
            switchCameraCallback();
        }
    }

    function handleTitleChange(title) {
        if (onTitleChangeRequest) {
            onTitleChangeRequest(title);
        }
    }


    function handleQRCode(qrCode) {
        if (onQRCode && qrCode) {
            onQRCode(qrCode);
        }
    }

    return (
        <React.Fragment>
            <DialogContent className={css.dialogContent}>
                <Camera
                    className={css.cameraContent}
                    photoEventRegister={registerTakePhoto}
                    onPhoto={handlePhotoIsTaken}
                    switchCameraEventRegister={registerSwitchPhoto}
                    onCameraSwitch={handleSwitchIsTaken}
                    onQRCode={handleQRCode}
                ></Camera>
            </DialogContent>
            <DialogActions>
                <Fab size={"small"} disabled={!CameraService.supportsDeviceEnumeration()} className={css.cameraFab}
                     color="primary" aria-label="make Photo!" onClick={handleSwitchCamera}>
                    <SwitchCameraIcon></SwitchCameraIcon>
                </Fab>
                <Fab size={"large"} className={css.cameraFab} color="primary" aria-label="make Photo!" autoFocus
                     onClick={handleMakePhoto}>
                    <PhotoCameraIcon></PhotoCameraIcon>
                </Fab>
                <Fab size={"small"} className={css.cameraFab} color="primary" aria-label="dummyicon" disabled>
                    <React.Fragment></React.Fragment>
                </Fab>
            </DialogActions>
        </React.Fragment>
    );
}

CameraDialogStep.defaultProps = {
    className: "",
    dialogTitle: "",
    onPhoto: () => {
    },
    onQRCode: () => {
    }
}


export default CameraDialogStep;