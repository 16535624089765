import React from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import FormGroup from "@material-ui/core/FormGroup";


function FormGroupAccordion(props) {
    const {id, label, row, className, ...args} = props;


    return (
        <Accordion {...args}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                id={id}
            >
                <Typography>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup className={className} row={row}>
                    {props.children}
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    );
}

FormGroupAccordion.defaultProps = {
    id: "",
    label: "",
    row: false,
    className: null
}


export default FormGroupAccordion;