import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import SimpleDialogTitle from "../SimpleDialogTitle/SimpleDialogTitle";


function SimpleDialog(props) {
    const {className, open, onClose, titleText} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [dialogIsOpen, setDialogIsOpen] = useState(true);


    function handleClose() {
        if (onClose) {
            onClose();
        }
        setDialogIsOpen(false);
    }


    useEffect(() => {
        setDialogIsOpen(open)
    }, [open])

    return (
        <Dialog className={className} open={dialogIsOpen} onClose={handleClose} aria-labelledby="dialog-title"
                fullScreen={fullScreen} fullWidth={true}>
            <SimpleDialogTitle onClose={handleClose} titleText={titleText}></SimpleDialogTitle>
            {props.children}
        </Dialog>
    );
}

SimpleDialog.defaultProps = {
    className: "",
    open: true
}


export default SimpleDialog;