import UtilService from "./UtilService";

const FileAPIService = (function () {

    const IMPORT_FILE_TYPES = {IMAGE: "image/*"};
    const EXPORT_FILE_TYPES = {VCARD: ".vcf"};

    function getImportFileTypes() {
        return IMPORT_FILE_TYPES;
    }

    function getExportFileTypes() {
        return EXPORT_FILE_TYPES;
    }


    function isSupported() {
        return UtilService.fileAPIisSupported();
    }

    //see: https://web.dev/progressively-enhance-your-pwa/
    async function exportFile(dataURL, type, fileName) {
        let fileType = type ? type : EXPORT_FILE_TYPES.VCARD;
        const linkElement = document.createElement('a');
        linkElement.download = fileName + fileType;
        linkElement.href = dataURL;
        linkElement.target = "_blank";
        linkElement.click();
    }


    //see: https://web.dev/progressively-enhance-your-pwa/
    //see: https://developer.mozilla.org/de/docs/Web/API/File/Using_files_from_web_applications
    // see : https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications
    async function importFile(type) {
        return new Promise((resolve, reject) => {
            const inputElement = document.createElement('input');
            inputElement.type = 'file';
            inputElement.accept = type ? type : IMPORT_FILE_TYPES.IMAGE;
            inputElement.addEventListener('change', (file) => {
                if (inputElement.files && inputElement.files.length > 0) {
                    const fileList = inputElement.files;
                    UtilService.toDataURL(fileList[0]).then((dataURl) => {
                        resolve(dataURl);
                    });

                } else {
                    reject("No file selected");
                }
            });
            inputElement.click();
        });
    }

    return {
        importFile: importFile,
        isSupported: isSupported,
        exportFile: exportFile,
        getImportFileTypes: getImportFileTypes,
        getExportFileTypes: getExportFileTypes
    }

})();


export default FileAPIService;