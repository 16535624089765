const NativeQRCodeScanner = (function () {

    let barcodeDetector;


    //see: https://wicg.github.io/shape-detection-api/
    const BarcodeFormatEnum = {
        aztec: "aztec",
        code_128: "code_128",
        code_39: "code_39",
        code_93: "code_93",
        codabar: "codabar",
        data_matrix: "data_matrix",
        ean_13: "ean_13",
        ean_8: "ean_8",
        itf: "itf",
        pdf417: "pdf417",
        qr_code: "qr_code",
        unknown: "unknown",
        upc_a: "upc_a",
        upc_e: "upc_e"
    };

    const STANDARD_CONFIG = {
        formats: [BarcodeFormatEnum.qr_code]
    };


    async function scanStream(videoElement, resolveParam) {
        let resolveFunction;
        let promise;
        if (!resolveParam) {
            promise = new Promise((resolve) => {
                resolveFunction = resolve;
            });
            await initQRCodeDetector();
        } else {
            resolveFunction = resolveParam;
        }

        //damit es keine Nullpointer exception gibt, nach Cleanup
        let currentDetector = barcodeDetector;

        if (videoElement && currentDetector) {
            window.requestAnimationFrame(() => {
                /*Api states, that it supports Video-Elements. Current Frame is used.
                 * see: https://wicg.github.io/shape-detection-api/#image-sources-for-detection
                 */
                if (videoElement.srcObject && videoElement.srcObject.active && !videoElement.ended) {
                    if (videoElement.readyState && videoElement.readyState > 1) {
                        currentDetector.detect(videoElement).then(function (result) {
                            if (!result || result.length < 1) {
                                scanStream(videoElement, resolveFunction);
                            } else {
                                resolveFunction(result);
                            }
                        })
                    } else {
                        scanStream(videoElement, resolveFunction);
                    }

                }
            });
        }

        if (promise) {
            return promise;
        }


    }

    //see https://web.dev/shape-detection/
    async function supportsNativeQRScanner() {
        if (('BarcodeDetector' in window)) {
            let result = (await window.BarcodeDetector.getSupportedFormats()).includes(BarcodeFormatEnum.qr_code);
            return result;
        }
        return false;
    }

    async function initQRCodeDetector() {
        if (!barcodeDetector) {
            let supportsQrCodeFeature = await supportsNativeQRScanner();
            if (!supportsQrCodeFeature) {
                throw "Doesn't support Native QR-Code-Scanner";
            }
            let currentBarCodeDetector = new window.BarcodeDetector(STANDARD_CONFIG);
            //sollte währenddessen cleanup aufgerufen werden, erhält scanImage tortzdem noch eine Referenz
            barcodeDetector = currentBarCodeDetector;
            return currentBarCodeDetector;
        }
        return barcodeDetector;
    }

    //see https://web.dev/shape-detection/
    async function detectQRCode(image) {
        const qrCodeDetector = initQRCodeDetector();
        const qrcodes = await qrCodeDetector.detect(image);
        return qrcodes;
    }

    //stoppt das scannen
    function cleanup() {
        barcodeDetector = null;
    }

    //see https://web.dev/shape-detection/
    async function hasQRCode(image) {
        const qrcodes = await detectQRCode();
        if (qrcodes && qrcodes.length > 0) {
            return true;
        }
        return false;
    }


    return {
        scanStream: (videoElement) => {
            return scanStream(videoElement);
        },
        detectQRCode: detectQRCode,
        cleanup: cleanup,
        hasQRCode: hasQRCode,
        supportsNativeQRScanner: supportsNativeQRScanner
    }

})();


export default NativeQRCodeScanner;