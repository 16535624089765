class UtilService {

    static firstChar(val) {
        if (val && val.length > 0) {
            return val.charAt(0);
        } else {
            return "";
        }
    }

    static isStreamActive(videoStream) {
        return (videoStream && videoStream.active);
    }

    //see: https://stackoverflow.com/questions/6518033/how-to-detect-browser-support-file-api-drag-n-drop
    static fileAPIisSupported() {
        const apiSupported = !!window.FileReader;
        console.log(apiSupported);
        return apiSupported;
    }

    static async dataURLtoFile(dataURL) {
        let response = await fetch(dataURL);
        if (response && response.blob) {
            return response.blob();
        } else {
            throw "Error transforming to File";
        }
    }

    static async toDataURL(file) {
        return new Promise((resolve, reject) => {
            if (file && this.fileAPIisSupported()) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener("load", () => {
                    if (reader.result) {
                        resolve(reader.result);
                    } else {
                        reject("Cannot convert File to ImageUrl")
                    }
                });
            } else {
                reject("Please Promote Parameter and Check if you are allowed to Call the service!");
            }
        });
    }
}

export default UtilService;