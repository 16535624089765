import React, {useEffect, useState} from 'react';
import BusinessCardQRCodeGenerator from "../../services/BusinessCardQRCodeGenerator";
import ProgressSpinner from "../ProgressSpinner/ProgressSpinner";


function BusinessCardQRCode(props) {
    const {businessCard} = props;
    const [imageURL, setImageURL] = useState(null);


    useEffect(() => {
        if (businessCard) {
            BusinessCardQRCodeGenerator.dtoToDataUrl(businessCard).then(function (qrCode) {
                setImageURL(qrCode);
            });
        }

    }, [businessCard])


    return (
        <React.Fragment>
            {imageURL ? <img src={imageURL} alt={"QRCode"}/> : <ProgressSpinner/>}
        </React.Fragment>
    );
}

BusinessCardQRCode.defaultProps = {
    className: "",
}


export default BusinessCardQRCode;