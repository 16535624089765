import localforage from "localforage";


/*non persistent Temp-Storage of business card
* */
const PersistentBusinessCardStore = (function () {

    const KEY_BUSINESSCARD_ARRAY = "BUSSINESSCARDS";

    const localBusinessCardStore = localforage.createInstance({
        name: "localBusinessCardStore"
    });

    function supportsPersistentStorage() {
        return localforage.supports(localforage.INDEXEDDB) ||
            localforage.supports(localforage.LOCALSTORAGE) ||
            localforage.supports(localforage.WEBSQL);
    }


    async function saveAll(cards) {
        return localBusinessCardStore.setItem(KEY_BUSINESSCARD_ARRAY, cards);
    }

    async function getAll() {
        return localBusinessCardStore.getItem(KEY_BUSINESSCARD_ARRAY);
    }


    return {
        saveAll: saveAll,
        getAll: getAll,
        supportsPersistentStorage: supportsPersistentStorage
    }
})();

export default PersistentBusinessCardStore;