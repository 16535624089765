import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";


function NewBusinessCardMenuItem(props) {
    const {itemText, itemIcon, ...args} = props;

    return (
        <MenuItem {...args} >
            <ListItemIcon>
                {itemIcon}
            </ListItemIcon>
            <Typography>{itemText}</Typography>
        </MenuItem>
    );
}

NewBusinessCardMenuItem.defaultProps = {
    itemText: "",
    itemIcon: "",
}

export default NewBusinessCardMenuItem;