import React, {useEffect, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SimpleCloseButton from "../SimpleCloseButton/SimpleCloseButton";


const useStyles = makeStyles((theme) => ({
    moveRight: {
        marginLeft: 'auto',
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'center'
    },
    dialogTitleText: {
        marginTop: "auto",
        marginBottom: "auto",
    }
}));


function SimpleDialogTitle(props) {
    const {className, onClose, titleText, closeButton} = props;
    const [title, setTitle] = useState("");
    const css = useStyles();

    useEffect(() => {
        setTitle(titleText)
    }, [titleText])

    function handleClose(event) {
        if (onClose) {
            onClose(event);
        }
    }


    return (
        <DialogTitle className={className ? className : css.dialogTitle} disableTypography={true} id="dialog-title">
            <Typography className={css.dialogTitleText} component={"h1"} variant={"h6"}
                        display={"inline"}>{title}</Typography>
            {closeButton ? closeButton :
                <SimpleCloseButton className={css.moveRight} onClick={handleClose}></SimpleCloseButton>}
        </DialogTitle>
    );
}

SimpleDialogTitle.defaultProps = {
    className: null,
    titleText: "",
}


export default SimpleDialogTitle;