import React, {useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import CardMedia from '@material-ui/core/CardMedia';
import FormInput from "../FormInput/FormInput";
import FormGroupAccordion from "../FormGroupAccordion/FormGroupAccordion";
import _ from 'lodash';
import BusinessCardAvatar from "../BusinessCardAvatar/BusinessCardAvatar";
import LinkIconButton from "../LinkIconButton/LinkIconButton";
import BusinessCardMappingService from "../../services/BusinessCardMappingService";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DashboardIcon from '@material-ui/icons/Dashboard';
import Button from '@material-ui/core/Button';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import GetAppIcon from '@material-ui/icons/GetApp';
import SimpleFileService from "../../services/SimpleFileService";


const useStyles = makeStyles((theme) => ({
    media: {
        height: 0,
        paddingTop: '20%'
    },
    formGroupSmall: {
        width: "100%"
    },
    formGroupLarge: {
//        width: "80%"
    },
    fullWidth: {
        width: "100%"
    },
    fullWidthInput: {
        width: "81%",
        margin: "0.25rem 1rem"
    },
    formInput: {
        margin: "0.25rem 1rem"
    },
    moveRight: {
        marginLeft: 'auto'
    }
}));

function BusinessCard(props) {
    const theme = useTheme();
    const isSmallDisplay = useMediaQuery(theme.breakpoints.down('xs'));
    const {value, onShowQRCode, onChange, readOnly, ...args} = props;
    const css = useStyles();
    const [isExpanded, setIsExpanded] = useState(true);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [businessCard, setBusinessCard] = useState(BusinessCardMappingService.epmtyBusinessCardDTO());


    useEffect(() => {
        setBusinessCard(value);
    }, [value])

    useEffect(() => {
        setIsReadOnly(readOnly);
    }, [readOnly])

    function handleExpand() {
        let expandTmp = isExpanded;
        setIsExpanded(!expandTmp);
    }

    function handleLockUnlock() {
        let lockState = !isReadOnly;
        setIsReadOnly(lockState);
    }

    function handleChange(event) {
        const {id, value} = event.target;
        if (id) {
            let businessCardTmp = _.cloneDeep(businessCard);
            businessCardTmp[id] = value;
            setBusinessCard(businessCardTmp);
            onChange(businessCardTmp);
        }
    }

    function handleShowQRCode() {
        if (onShowQRCode) {
            onShowQRCode(businessCard.uuid);
        }
    }

    function handleVCardExport() {
        if (businessCard) {
            let vCardDataURL = BusinessCardMappingService.businessCardDTOToVCardDataURL(businessCard);
            let exportFileType = SimpleFileService.getExportFileTypes().VCARD;
            SimpleFileService.exportFile(vCardDataURL, exportFileType, businessCard.uuid);
        }
    }


    return (
        <Card {...args}>
            <CardHeader
                avatar={
                    <BusinessCardAvatar businesscard={businessCard}/>
                }
                action={
                    <LinkIconButton aria-label="close business card" to="/">
                        <CloseIcon/>
                    </LinkIconButton>
                }
                title={businessCard.firstName + " " + businessCard.lastName}
                subheader={businessCard.uuid}
            />
            {!_.isEmpty(businessCard.image) && <CardMedia
                className={css.media}
                image={businessCard.image}
                title="Photo of businnesscard"
            />}
            <CardActions>
                <Button className={css.moveRight} startIcon={<DashboardIcon></DashboardIcon>} onClick={handleShowQRCode}
                        size="small"
                        color="primary">
                    QR-Code
                </Button>
                <Button startIcon={<GetAppIcon></GetAppIcon>} onClick={handleVCardExport} size="small" color="primary">
                    Export
                </Button>
            </CardActions>
            <CardContent>
                <form noValidate autoComplete="off">
                    <FormGroupAccordion id="persondata"
                                        className={isSmallDisplay ? css.formGroupSmall : css.formGroupLarge}
                                        row={!isSmallDisplay} label="Person-Data" expanded={isExpanded}
                                        onChange={handleExpand}>
                        <FormInput id={"firstName"} className={css.formInput} label="First name"
                                   helperText={"Enter here the First Name"} value={businessCard.firstName}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                        <FormInput id={"lastName"} className={css.formInput} label="Last Name"
                                   helperText={"Enter here the Last Name"} value={businessCard.lastName}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                        <FormInput id={"middleName"} className={css.formInput} label="Middle Name"
                                   helperText={"Enter here the Middle Name"} value={businessCard.middleName}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                        <FormInput id={"extraName"} className={css.formInput} label="Extra Name"
                                   helperText={"Enter here the Additional Names"} value={businessCard.extraName}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                    </FormGroupAccordion>
                    <FormGroupAccordion id="jobinfo"
                                        className={isSmallDisplay ? css.formGroupSmall : css.formGroupLarge}
                                        row={!isSmallDisplay} label="Job-Info">
                        <FormInput id={"job"} label="Job" className={css.formInput}
                                   helperText={"Enter here the Jobname"} value={businessCard.job} readOnly={isReadOnly}
                                   onChange={handleChange}/>
                        <FormInput id={"company"} label="Company" className={css.formInput}
                                   helperText={"Enter here the Organisation Name"} value={businessCard.company}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                    </FormGroupAccordion>
                    <FormGroupAccordion id="address"
                                        className={isSmallDisplay ? css.formGroupSmall : css.formGroupLarge}
                                        row={!isSmallDisplay} label="Address">
                        <FormInput id={"street"} label="Street" className={css.formInput}
                                   helperText={"Enter here the street"} value={businessCard.street}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                        <FormInput id={"plz"} label="ZIP" className={css.formInput}
                                   helperText={"Enter here the ZipCode"} value={businessCard.plz} readOnly={isReadOnly}
                                   onChange={handleChange}/>
                        <FormInput id={"city"} label="City" className={css.formInput} helperText={"Enter here the City"}
                                   value={businessCard.city} readOnly={isReadOnly} onChange={handleChange}/>
                        <FormInput id={"country"} label="Country" className={css.formInput}
                                   helperText={"Enter here the country-Name"} value={businessCard.country}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                        <FormInput id={"label"} label="Label" className={css.fullWidthInput}
                                   helperText={"Enter here an Adress-Label"} value={businessCard.label}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                    </FormGroupAccordion>
                    <FormGroupAccordion id="contactdata"
                                        className={isSmallDisplay ? css.formGroupSmall : css.formGroupLarge}
                                        row={!isSmallDisplay} label="Contact-Data">
                        <FormInput id={"phone"} label="Phone" className={css.formInput}
                                   helperText={"Enter here the Phone-Number"} value={businessCard.phone}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                        <FormInput id={"mobile"} label="Mobile" className={css.formInput}
                                   helperText={"Enter here the Mobile-Number"} value={businessCard.mobile}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                        <FormInput id={"email"} label="E-Mail" className={css.formInput}
                                   helperText={"Enter here a E-Mail-Adress"} value={businessCard.email}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                        <FormInput id={"web"} label="Web-Adress" className={css.formInput}
                                   helperText={"Enter here a Web-Adress"} value={businessCard.web} readOnly={isReadOnly}
                                   onChange={handleChange}/>
                        <FormInput id={"fax"} label="Fax" className={css.formInput}
                                   helperText={"Enter here an Fax-Adress"} value={businessCard.fax}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                    </FormGroupAccordion>
                    <FormGroupAccordion id="Notes" className={css.fullWidth} label="Notes">
                        <FormInput id={"notes"} className={css.fullWidthInput} multiline rows={4} label="Notes"
                                   helperText={"Enter here aditional Notes and infos"} value={businessCard.notes}
                                   readOnly={isReadOnly} onChange={handleChange}/>
                    </FormGroupAccordion>
                </form>
            </CardContent>
            <CardActions>
                <Button className={css.moveRight}
                        startIcon={isReadOnly ? <LockOpenIcon></LockOpenIcon> : <LockIcon></LockIcon>}
                        onClick={handleLockUnlock} color="primary">
                    {isReadOnly ? "Unlock" : "Lock"}
                </Button>
            </CardActions>
        </Card>
    );
}

BusinessCard.defaultProps = {
    className: "",
    readOnly: false
}

export default BusinessCard;