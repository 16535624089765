import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

function SimpleCloseButton(props) {

    return (
        <IconButton aria-label="close" {...props}>
            <CloseIcon/>
        </IconButton>
    );
}

export default SimpleCloseButton;